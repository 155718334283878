<template>
    <div>
        <title-component title-text="General" />
        <div class="P-inputs G-flex flex-column">
            <div class="P-inputs-block">
                <ott-input
                    label="Name"
                    v-model="name"
                    :error="$v.formData.name.$error"
                />
            </div>
            <div class="P-inputs-block">
                <quill-editor 
                    v-model="description" 
                    :options="{ placeholder: 'Description' }"
                    :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
                />
            </div>
        </div>
        <ott-button-loader
            :is-loading="isLoading"
            @onClick="saveData"
            :button-text="equipmentTypeId ? 'Save' : 'Add'"
            class-name="primary-sm-btn mt-4"
        />
    </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";
    import {required} from "vuelidate/lib/validators";
    // components
    import OttInput from "@/components/vuetifyComponents/OttInput";
    import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
    import TitleComponent from "@/components/TitleComponent";
    export default {
        name: "General",
        components: {TitleComponent, OttButtonLoader, OttInput},
        validations: {
            formData: {
                name: { required }
            }
        },
        computed: {
            ...mapState({
                formData: state => state.equipmentsTypesModule.formData,
                isLoading: state => state.appModule.isLoading,
                equipmentTypeId: state => state.equipmentsTypesModule.equipmentTypeId
            }),
            name: {
                get() {
                    return this.formData.name
                },
                set(value){
                    this.setName(value)
                }
            },
            description: {
                get() {
                    return this.formData.description
                },
                set(value){
                    this.setDescription(value)
                }
            }
        },
        methods: {
            ...mapActions({
                createEquipmentType: 'equipmentsTypesModule/createEquipmentType',
                updateEquipmentType: 'equipmentsTypesModule/updateEquipmentType'
            }),
            ...mapMutations({
                setData: 'equipmentsTypesModule/setData',
                setName: 'equipmentsTypesModule/setName',
                setDescription: 'equipmentsTypesModule/setDescription'
            }),
            async saveData() {
                this.$v.formData.$touch()

                if (!this.$v.formData.$error) {
                    if (this.equipmentTypeId) {
                        await this.updateEquipmentType(this.equipmentTypeId)
                    } else {
                        await this.createEquipmentType()
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.P-inputs {
    margin: 10px -10px 0;
    &-block {
        width: 100%;
        padding: 0 10px;
    }
}

::v-deep {
    .P-loader-button {
        width: 100px;
    }
}
</style>