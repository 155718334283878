<template>
  <selected-filters 
    :selectedList="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";

export default {
  components: { SelectedFilters },

  computed: {
    ...mapState({
      filterModel: (state) => state.equipmentsTypesModule.filterModel,
      filter: (state) => state.equipmentsTypesModule.filterData,
    }),

    selectedList() {
      return [
        {
          ifCondition: !!this.filterModel.search,
          boxText: `<b>Search</b>: ${this.filterModel.search}`,
          resetArgument: "search",
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      getEquipmentsTypesList: "equipmentsTypesModule/getEquipmentsTypesList",
    }),

    ...mapMutations({
      filterEquipmentTypes: "equipmentsTypesModule/filterEquipmentTypes",
    }),

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterEquipmentTypes();
      await this.getEquipmentsTypesList(this.filter);
    },
  },
};
</script>
