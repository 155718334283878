<template>
  <div class="P-users-list">
    <div class="G-page-component" :class="{ 'P-filter-open': isOpenFilter }">
      <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title d-flex">
          <h3 class="secondary--text">Equipment Types</h3>
        </div>
        <ott-button
          v-if="permissionIs('createEquipmentType')"
          :click="openModal"
          class="primary-sm-btn"
          icon-before
          text="Add Equipment Type"
          icon-before-class="mdi mdi-plus"
        />
      </div>
      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <ott-checkbox
              :indeterminate="selectNotAll"
              :dataVal="selectAllList"
              tooltip="Select all visibles"
              @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>
            <div
              v-if="
                (selectedArrowList.length || selectedArrowListSub.length) &&
                permissionIs('deleteEquipmentType')
              "
              class="approve-rej-icon-cont G-flex G-align-center"
            >
              <v-tooltip
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    @click="multipleDelete"
                    class="P-settings"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="mdi mdi-delete-outline neutral--text P-icon" />
                  </div>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </div>
          <div class="G-excel-btn">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="P-settings" v-bind="attrs" v-on="on">
                  <span class="mdi mdi-file-excel neutral--text P-icon" />
                </div>
              </template>
              <span>Download Excel</span>
            </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            :column-config="columnConfigData"
            :is-default="isDefault"
            :containerStyles="{ width: '380px' }"
            @resetDataSettingsTable="resetDataTableSettings"
            @updateColumnsFromCheck="updateColumnsFromCheck"
            class="P-users-columns"
          />
        </div>
        <div>
          <div
            class="G-filter-btn"
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
              v-if="filterModel.filterCount.length > 0"
              class="active-filter-count"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div
        class="G-component-body page-background"
      >
        <div class="G-sub-block-title">
          <h3 class="secondary--text">Equipment Type List</h3>
        </div>
        <OttUniversalTable
          v-if="!loading"
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="equipmentsType.dataTable"
          :is-sub-table="false"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :main-data="equipmentsType.dataTable"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="false"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortEquipmentsTypeData"
          @updateColumnWith="updateColumn"
        >
          <template
            v-if="
              permissionIs('updateEquipmentType') ||
              permissionIs('deleteEquipmentType')
            "
            slot-scope="props"
            slot="action"
          >
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in actionsList"
                  :key="index"
                  @click="selectAction($event, props, item.value)"
                >
                  <div>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </OttUniversalTable>
        <OttPaginationForTable
          v-model="rowsPerPage"
          v-if="equipmentsType.equipmentsTypeResult && !loading"
          :data-count="equipmentsType.dataCount"
          :available-data-count="equipmentsType.dataTable.length"
          :current-page="equipmentsType.currentPage"
          :page-limit="equipmentsType.totalPages"
          @update="updateLimit"
          @updatePage="updateEquipmentsTypes"
        />
        <NoDataInTable v-else />
      </div>
    </div>
    <div
      :class="{ 'P-filter-open': isOpenFilter }"
      class="G-filter-component page-background"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList" />
      </div>
      <div class="G-filter-footer">
        <ott-button
          class="primary-lg-btn"
          text="Reset Filters"
          :click="resetData"
        >
        </ott-button>
      </div>
    </div>

    <PageLoader v-if="equipmentsType.isTableLoading || loading" />

    <ManageEquipmentType />
    <WarningModal
      v-if="equipmentsType.showWarningModal"
      :isModalOpen="equipmentsType.showWarningModal"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="closeWarningModal"
      @btnFunction="warningModalBtnFunc"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
// mixins
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
import FilterMixin from "@/mixins/FilterMixin";
import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";

// components
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import NoDataInTable from "@/components/NoDataInTable";
import FilterData from "./components/FilterData";
import PageLoader from "../../components/pageLoader/PageLoader";
import ManageEquipmentType from "./modals/ManageEquipmentType";
// modals
import WarningModal from "@/components/WarningModal";

export default {
  name: "EquipmentsTypes",
  components: {
    ManageEquipmentType,
    PageLoader,
    NoDataInTable,
    OttPaginationForTable,
    OttUniversalTable,
    OttHideShowColumnsOption,
    OttCheckbox,
    OttButton,
    FilterData,
    WarningModal,
  },
  mixins: [OttUniversalTableForComponentsMixin, FilterMixin, ColumnConfigMixin],
  data() {
    return {
      defaultColumnConfigData: [],
      columnConfigData: [],
      multipleActions: [{ name: "Delete", value: "multipleDelete" }],
      deletedClientId: null,
      warningModalType: "",
      warningModalBtnText: "",
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      loading: false
    };
  },
  async created() {
    this.loading = true;
    await this.getEquipmentTypesSettings();
    this.createColumnConfigData();
    this.filterEquipmentTypes();
    this.sortColumn();
    await this.getEquipmentsTypesList(this.equipmentsType.filterData).finally(() => this.loading = false);
    this.resetSelectedList();
  },

  beforeDestroy() {
    this.filterModel.reset()
    this.setData({ filterData: {} })
  },
  destroyed() {
    this.setFilterPage(1)
  },
  computed: {
    ...mapState({
      equipmentsType: (state) => state.equipmentsTypesModule,
      dataTable: (state) => state.equipmentsTypesModule.dataTable,
      isDefault: (state) => state.equipmentsTypesModule.isDefault,
      defaultSettings: state => state.equipmentsTypesModule.defaultSettings,
      filter: (state) => state.equipmentsTypesModule.filter,
      filterModel: (state) => state.equipmentsTypesModule.filterModel,
      updateColumnsFromBack: (state) => state.equipmentsTypesModule.updateColumnsFromBack,
      updateColumnSettingsCommit: state => state.equipmentsTypesModule.updateColumnSettingsCommit,
    }),

    defaultColumnConfigs() {
      return [
        {
          title: "ID",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "number",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 0,
          mainIndex: 0,
          cellView: (row) => `<p class="G-text-table">${row.number}</p>`,
        },
        {
          title: "Name",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "name",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 1,
          mainIndex: 1,
          sortData: {
            isSort: true,
            sortKey: "name",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.name.length
                ? row.name.map((name) =>
                    name.lang === "en" ? name.name : row.name[0].name
                  )[0]
                : "-"
            } </p>`,
        },
        {
          title: "Description",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "description",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 2,
          mainIndex: 2,
          cellView: (row) => `<p class="G-text-table">${row?.description}</p>`,
        },
      ];
    },

    rowsPerPage: {
      set(limit) {
        this.setLimit(limit)
      },
      get() {
        return this.filter.limit;
      }
    },

    warningModalHeading() {
      return `${this.warningModalBtnText} Location(s)?`;
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> one or more Locations! <br />  If you're not sure, you can cancel this operation.`;
    },
    
    actionsList() {
      const actions = [];

      if (this.permissionIs("updateEquipmentType")) {
        actions.push({ title: "Edit", value: "edit" });
      }

      if (this.permissionIs("deleteEquipmentType")) {
        actions.push({ title: "Delete", value: "delete" });
      }

      return actions;
    },
  },
  methods: {
    ...mapActions({
      getEquipmentsTypesList: "equipmentsTypesModule/getEquipmentsTypesList",
      getEquipmentTypeData: "equipmentsTypesModule/getEquipmentTypeData",
      equipmentTypeDelete: "equipmentsTypesModule/equipmentTypeDelete",
      getEquipmentTypesSettings: "equipmentsTypesModule/getEquipmentTypesSettings"
    }),
    ...mapMutations({
      setData: "equipmentsTypesModule/setData",
      setLimit: 'equipmentsTypesModule/setLimit',
      setFilterPage: "equipmentsTypesModule/setFilterPage",
      sortColumn: "equipmentsTypesModule/sortColumn",
      filterEquipmentTypes: "equipmentsTypesModule/filterEquipmentTypes",
    }),

    openModal() {
      this.setData({ isOpenManageEquipmentModal: true });
    },

    async resetDataTableSettings() {
      await this.resetDataTable();
      await this.getEquipmentTypesSettings();
      this.createColumnConfigData();
    },

    resetData() {},

    async sortEquipmentsTypeData(data) {
      this.sortColumn(data);
      await this.getEquipmentsTypesList(this.equipmentsType.filterData);
    },

    async updateLimit() {
      await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
        this.createSaveDefaultData();
      })
      this.sortColumn();
      
      await this.getEquipmentsTypesList(this.equipmentsType.filterData);
      this.resetSelectedList();
    },

    async updateEquipmentsTypes(page) {
      if (page) {
        this.setFilterPage(page);
      }
      this.sortColumn();
      await this.getEquipmentsTypesList(this.equipmentsType.filterData);
      this.resetSelectedList();
    },

    async selectAction(event, data, action) {
      let equipmentTypeID = data.rowData.id;
      this.setData({ equipmentTypeId: equipmentTypeID });
      switch (action) {
        case "edit":
          {
            await this.getEquipmentTypeData(equipmentTypeID);
            this.setData({ isOpenManageEquipmentModal: true });
          }
          break;
        case "delete":
          {
            this.warningModalBtnText = "Delete";
            this.warningModalType = "delete";
            this.warningModalIsDangerButton = true;
            this.setData({ showWarningModal: true });
            this.deletedClientId = equipmentTypeID;
          }
          break;
      }
    },

    async warningModalBtnFunc() {
      await this.warningModalBtnFunction().finally(() => {
        this.closeWarningModal();
      });
    },

    async warningModalBtnFunction() {
      this.warningModalIsLoading = true;
      switch (this.warningModalType) {
        case "delete":
        case "multipleDelete":
          await this.deleteEquipmentType();
          break;
      }
    },

    multipleDelete() {
      this.warningModalBtnText = "Delete";
      this.warningModalType = "delete";
      this.warningModalIsDangerButton = true;
      this.setData({ showWarningModal: true });
    },

    async deleteEquipmentType() {
      if (this.deletedClientId) {
        await this.equipmentTypeDelete({
          equipmentTypeId: [this.deletedClientId],
        });
      } else if (this.selectedArrowList.length) {
        const data = {}

        if (this.isSelectedAll) {
          data.all = true
        } else {
          data.equipmentTypeId = [...this.selectedArrowList.map((item) => item.id)]
        }
        
        await this.equipmentTypeDelete(data);
        this.resetSelectedList();
      }
    },

    closeWarningModal() {
      this.warningModalIsLoading = false;
      this.setData({ showWarningModal: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables.scss";

.P-users-list {
  position: relative;
}

.P-users-columns {
  padding-left: 10px;
}

.v-menu__content {
  max-width: 190px !important;
}
.approve-rej-icon-cont {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1.5px solid #a5aac6;
}
</style>