<template>
  <ott-dialog
    v-if="isOpenManageEquipmentModal"
    :is-open="isOpenManageEquipmentModal"
    :show-footer="false"
    :modal-title="'Add Equipment type'"
    :width="890"
    @outside="closeModal"
    @resetDataModal="closeModal"
  >
    <template v-slot:modalBody>
      <div class="parent-add-provider-modal">
        <div class="left P-background-neutral7 G-flex-column G-justify-between">
          <div>
            <ul class="list">
              <li class="listActive">General</li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="main">
            <General />
            <Transition name="fade">
              <v-alert
                v-if="isSaved"
                dense
                text
                class="mt-5"
                type="success"
                style="width: max-content"
              >
                The request has been saved successfully
              </v-alert>
            </Transition>
          </div>
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
// components
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import General from "./General";
export default {
  name: "ManageEquipmentType",
  components: { General, OttDialog },
  computed: {
    ...mapState({
      isSaved: (state) => state.appModule.isSaved,
      isOpenManageEquipmentModal: (state) =>
        state.equipmentsTypesModule.isOpenManageEquipmentModal,
    }),
  },
  methods: {
    ...mapMutations({
      setData: "equipmentsTypesModule/setData",
      resetFormData: "equipmentsTypesModule/resetFormData",
    }),
    closeModal() {
      this.setData({ isOpenManageEquipmentModal: false });
      this.resetFormData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.parent-add-provider-modal {
  display: flex;

  .left {
    min-width: 200px;
    padding: 18px 14px 24px;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .list {
      padding-left: 0;
      overflow-y: scroll;
      height: 500px;
      text-transform: capitalize;

      li {
        padding: 7px 11px;
        cursor: pointer;
        border-radius: 4px;
        font: normal normal 600 14px Segoe UI;
        white-space: nowrap;
        transition: $transition;
      }
    }
  }

  .right {
    padding: 14px 24px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #a5aac6;
      margin: 24px -24px 0 -24px;
      padding: 24px 24px 0;
    }
    ::v-deep {
      .P-modal-component-header {
        margin-bottom: 0;
      }
    }
  }
}

.theme--dark {
  .left {
    background-color: rgba(48, 49, 52, 0.43);
  }

}
</style>