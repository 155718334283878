<template>
    <div class="P-filters">
        <div class="P-input-block">
            <ott-input
                v-model="filterModel.search"
                @timeoutInput="filterData('search')"
                :timeoutInput="true"
                label="Search"
                prependInnerIcon="mdi mdi-magnify neutral--text"
            />
        </div>
       
        <selected-filters-list v-if="filterModel.filterCount.length" />
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import OttInput from "@/components/vuetifyComponents/OttInput";
    import SelectedFiltersList from "./SelectedFiltersList";

    export default {
        name: "FilterData",
        components: { OttInput, SelectedFiltersList },
        computed: {
            ...mapState({
                filterModel: state => state.equipmentsTypesModule.filterModel,
                filter: state => state.equipmentsTypesModule.filterData,
            })
        },

        methods: {
            ...mapActions({
                getEquipmentsTypesList: "equipmentsTypesModule/getEquipmentsTypesList"
            }),

            ...mapMutations({
                filterEquipmentTypes: "equipmentsTypesModule/filterEquipmentTypes",
                setFilterPage: "equipmentsTypesModule/setFilterPage",
                sortColumn: "equipmentsTypesModule/sortColumn",
            }),
            
            async filterData(type) {
                this.setFilterPage(1)
                this.filterEquipmentTypes();
                this.filterModel.getFilterCount();
                this.sortColumn();
                await this.getEquipmentsTypesList(this.filter);
                this.$emit('resetSelectedList')
            }
        }
    }
</script>

<style scoped>

</style>